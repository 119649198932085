$primary-brand: #F09A45;
$primary-disabled: #F8D6B6;
$secondary: #3954B0;
$secondary-disabled: #99B0E2;
$light-bg: #F3F7FD;
$light-system: #C5CCD9;
$dark-system: #7B828F;
$black-system: #1E242E;
$system-error: #e82121;
$system-success: #5cb85c;

@mixin respond($breakpoint) {
    @media only screen and (max-width: ($breakpoint + px)) {
      @content;
    }
}

*{
    box-sizing: border-box;
}

::-webkit-scrollbar{
    width: 8px;
    height: 8px;
}
::-webkit-scrollbar-thumb{
    background: #F09A45;
    border-radius: 30px;
    background-clip: padding-box;
    
}
::-webkit-scrollbar-thumb:hover{
    background: #ce751c;
    background-clip: padding-box;
}
::-webkit-scrollbar-track{
    background: transparent;  
    margin: 30px 0;
}

.system-success-bckg{
    background: $system-success !important;
}
.primary-brand-bckg{
    background: $primary-brand !important;
}
.primary-brand{
    color: $primary-brand !important;
}
.primary-disabled-bckg{
    background: $primary-disabled !important;
}
.primary-disabled{
    color: $primary-disabled !important;
}
.secondary-bckg{
    background: $secondary !important;
}
.secondary{
    color: $secondary !important;
}
.secondary-disabled-bckg{
    background: $secondary-disabled !important;
 //   cursor: not-allowed !important;
}
.secondary-disabled{
    color: $secondary-disabled !important;
}
.light-bg-bckg{
    background: $light-bg !important;
}
.light-bg{
    color: $light-bg !important;
}
.light-system-bckg{
    background: $light-system !important;
}
.light-system{
    color: $light-system !important;
}
.dark-system-bckg{
    background: $dark-system !important;
}
.dark-system{
    color: $dark-system !important;
}
.black-system-bckg{
    background: $black-system !important;
}
.black-system{
    color: $black-system !important;
}

@font-face {
    font-family: "Roboto";
    src: local("Roboto"), url("../fonts/Roboto-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: "Roboto";
    src: local("Roboto"), url("../fonts/Roboto-BlackItalic.ttf") format("truetype");
    font-weight: 900;
    font-style: italic;
}
@font-face {
    font-family: "Roboto";
    src: local("Roboto"), url("../fonts/Roboto-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: "Roboto";
    src: local("Roboto"), url("../fonts/Roboto-BoldItalic.ttf") format("truetype");
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: "Roboto";
    src: local("Roboto"), url("../fonts/Roboto-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "Roboto";
    src: local("Roboto"), url("../fonts/Roboto-MediumItalic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: "Roboto";
    src: local("Roboto"), url("../fonts/Roboto-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "Roboto";
    src: local("Roboto"), url("../fonts/Roboto-Italic.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: "Roboto";
    src: local("Roboto"), url("../fonts/Roboto-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "Roboto";
    src: local("Roboto"), url("../fonts/Roboto-LightItalic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: "Roboto";
    src: local("Roboto"), url("../fonts/Roboto-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: "Roboto";
    src: local("Roboto"), url("../fonts/Roboto-ThinItalic.ttf") format("truetype");
    font-weight: 100;
    font-style: italic;
}

body{
    font-family: "Roboto";
    color: $black-system;
    box-sizing: border-box;
    overflow-x: hidden;

    p{
        margin: 0;
    }
    .App.responsive{
        width: 100%;
        height: 100vh;
        background: url('../images/bckg-login.png');
        background-repeat: no-repeat;
        
        @include respond(500) {
            background-position-y: -100px;
        }

        .container{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            background: $light-bg;
            display: flex;
            flex-direction:column;
            justify-content: center;
            align-items: center;
            box-shadow: 0px 6px 40px rgba(0, 0, 0, 0.1), 0px 8px 20px rgba(0, 0, 0, 0.05);
            -webkit-box-shadow: 0px 6px 40px rgba(0, 0, 0, 0.1), 0px 8px 20px rgba(0, 0, 0, 0.05);
            -moz-box-shadow: 0px 6px 40px rgba(0, 0, 0, 0.1), 0px 8px 20px rgba(0, 0, 0, 0.05);
            border-radius: 30px;
            padding: 40px;
            width: 30%;

            @include respond(690) {
                width: 40%;
            }

            @include respond(520) {
                padding: 40px 30px;
                width: 60%;
            }

            h1, h3{
                color: $black-system;
            }
        }
    }

    .pre-loader{
        position: fixed;
        width: 100vw;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 99999;
        background: rgba(255, 255, 255, 1);
        display: flex;
        flex-direction: column;
        align-items: center;

        .lottie2{
            width: 100%;
            position: absolute;
            top: 50%;
            left: 0%;
            
            transform: translateY(-50%);
        }
    
    }
}



