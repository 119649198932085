@import "theme.scss";

.MuiBox-root{

    .register-block{
        display:flex;
        //column-gap: 5%;
        form{
            margin-top: 40px;
            width: 55%;

            &.fullwidth{
                width: 100%;
            }

            .MuiAccordion-root{
                margin-top: 20px;

                .MuiAccordionDetails-root{
                    .img-container{
                        width: 100%;
                    }
                }
            }

            h3{
                text-align:center;
                margin-bottom: 15px;
            }

            .fields{
                display:flex;
                flex-direction: column;
                row-gap: 20px;
                input{
                    height: 28px;
                    padding: 10px 15px;
                }
            }

            .dropzone-ui{
               height: 200px;
               min-height: unset;
               width: 100%;
            }

            .seller-add{
                width: 100%;
                margin-top: 20px;
                box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.05);
            }

            .submit-btn{
                margin-top: 20px;
                height: 56px;
                box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.05);
            }
        }

        .courses-cats-info{
            width: 45%;
            padding-left: 50px;
            margin-top: 20px;          

            .assigned-courses, .assigned-categories {      
                display: flex;
                flex-direction: column;
                max-height: 200px;
                overflow-y: auto;
            }

            h4:last-of-type{
                margin-top: 50px;
            }
        }
    }

    .update-user-block{
        form{
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            row-gap: 20px;

            .fields{
                display: flex;
                flex-direction: column;
                row-gap: 20px;
            }

            .user_img{
                p{
                    margin-bottom: 10px;
                }

                .img-wrapper{
                    height: 200px;
                    margin-bottom: 10px;

                    img{
                        height: 100%;
                        border-radius: 15px;
                        width: 200px;
                        object-fit: cover;
                        object-position: center;
                    }
                }
            }

            h3{
                margin-bottom: 20px;
            }

            h4{
                margin: 0;
            }

            .assigned-categories{
                margin-top: -20px;
            }

            .seller-fields{
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                margin-bottom: 20px;

                p{
                    font-weight: 700;
                    padding: 5px 10px;
                    border-radius: 50%;
                    background: $secondary-disabled;       
                }

                .fields{
                    width: 90%;
                }
            }

            .seller-add{
               margin: 0 auto 20px; 
            }
        }
    }

    &.MuiBox-root-14, &.MuiBox-root-8{
        @include respond(1800) {
            width: 50%;
        }

        @include respond(1300) {
            width: 60%;
        }
    }

    &.modal-managment{
        width: 100% !important;
    }
}