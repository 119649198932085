@import "theme.scss";

body{
    background: $light-bg;
    .App{
        .pres-container{
            padding: 150px 72px;

            .register-wrapper{
                display: flex;
                column-gap: 32px;
                margin-bottom: 40px;

                button, a{
                    padding: 14px 30px;
                    font-weight: 700;
                    font-size: 17px;
                    color: #000;
                    border-radius: 8px;
                    text-decoration: none;
                    box-shadow: 0px 2px 12px rgb(0 0 0 / 10%), 0px 2px 4px rgb(0 0 0 / 5%);
                    -webkit-box-shadow: 0px 2px 12px rgb(0 0 0 / 10%), 0px 2px 4px rgb(0 0 0 / 5%);
                    transition: all ease-out 200ms;
                    display: flex;
                    align-items: center;
                    column-gap: 10px;

                    &:hover{
                        transform: scale(1.03);
                        box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
                        -webkit-box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
                    }
                }
            }

            .container-block1{            
                h2{
                    text-align: center;
                } 
                
                .category-block{
                    margin-bottom: 80px;
                }
            }
        
            .wrapper{
                display: flex;
                align-items: center;
                justify-content: space-between;
        
                .present-container{
                    .container-block1{
                        display: flex;
                        column-gap: 16px;
                        align-items: center;
        
                        button.MuiButton-root{
                            min-width: unset;
                        }
                    }
                }
        
                .categories-block{
                    button.MuiButton-contained{
                        font-weight: 700;
                    }
                }
            }
        
            .container-block1{    
                .item-blocks{
                    display: flex;
                    flex-wrap: wrap;
                    column-gap: 30px;
                    row-gap: 30px;
        
                    .item-block{
                        width: 17%;
                        position: relative;

                        @include respond(1600) {
                            width: 23%;
                        }

                        @include respond(1200) {
                            width: 30%;
                        }
        
                        .buttons-group{
                            position:absolute;
                            top: 2%;
                            right: 2%;
                            z-index: 3;

                            button{
                                padding: 0;
                                min-width: unset;
                                font-size: 20px;
                                transition: all ease-out 150ms;

                                &:hover{
                                    background: unset;
                                    transform: scale(1.2);
                                }
                            }
                        }
        
                        .img-wrapper{
                            height: 200px;
                            border-radius: 15px;
                            -webkit-box-shadow: 0px 0px 15px 1px #0000001c; 
                            box-shadow: 0px 0px 15px 1px #0000001c;
                            position: relative;
                            transition: all ease-out 300ms;
                            cursor: pointer;
                            z-index: 2;
                            overflow: hidden;
        
                            img{
                                max-width: 100%;
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                object-position: center;
                                opacity: 1;
                                transition: all ease-out 300ms;
                            }

                            .bigPlus{
                                position:absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                opacity: 0;
                                visibility:hidden;
                                transition: all ease-out 300ms;
                                z-index: 2;
                                
                                svg{
                                    width: 2.5em;
                                    height: 2.5em;
                                }
                            }

                            &:hover{
                                .bigPlus{
                                    opacity: 1;
                                    visibility: visible;                        
                                }

                                img{
                                    opacity: 0.5;
                                }
                            }
                        }
        
                        .presentation-title{
                            text-align: center;
                            font-weight:700;
                            padding-top: 10px;
                        }
                    }
                }
            }
        
            .add-img{
                display: flex;
                column-gap: 15px;
            
                .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
                    padding: 7px 14px;
                }
            }
        }
    }

    .MuiBox-root{
        border: none !important;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 2px 5px rgba(0, 0, 0, 0.1);
        border-radius: 20px;

        button.close-modal-btn{
            min-width: unset;
            position: absolute;
            top: 0.5%;
            left: 1%;
        }

        button.back-modal-btn{
            min-width: unset;
            position: absolute;
            top: 0;
            right: 2%;
        }

        .select-cat{
            padding: 30px 0;

            label{
                color: #000;
                font-weight: 700;
                padding-bottom: 5px;
            }

            .MuiInput-underline{
                width: 100%;
            }
        }

        button{
            font-weight: 700;
        }

        .MuiFormControl-root{
            .input-wrapper{
                padding-bottom: 16px;
                .MuiInput-underline{
                    width: 100%;
                }
            }
        }

        h3{
            margin-bottom: 0;
        }

        .categories-container{
            display: flex;
            flex-direction: column;
            row-gap: 10px;

            .catBlock{
                display: flex;
                align-items: center;
                justify-content: space-between;

                .buttonsGroup{

                    button{
                        padding: 5px;
                        min-width: unset;
                        transition: all ease-out 150ms;

                        &:hover{
                            background-color: unset;
                            transform: scale(1.05);
                        }
                    }
                }
            }
        }

        .presentation-container{

            .catName{
                display: flex;
                justify-content: center;
                align-items: flex-start;
                column-gap: 15px;
            }

            .select-cat{
                width: 20%;

                .select-container{
                    display: flex;
                    column-gap: 15px;
                    margin-top: 10px;
                }
            }

            h3{
                margin-bottom: 10px;
            }
    
            .MuiAccordion-root{
                .MuiAccordionDetails-root{
                    flex-direction: column;
                    align-items: center;
                    row-gap: 16px;
    
                    .slide-container{
                        width: 100%;
                        display:flex;
                        flex-direction: column;
                        align-items: center;
                        row-gap: 16px;
                    }
                }
            }

            .item-blocks{
                margin-top: 20px;

                .custom-dropping{
                    display: flex;
                    flex-wrap: wrap;
                    grid-column-gap: 30px;
                    column-gap: 30px;
                    grid-row-gap: 30px;
                    row-gap: 30px;

                    @include respond(1200) {
                        grid-column-gap: 20px;
                        column-gap: 20px;
                        grid-row-gap: 20px;
                        row-gap: 20px;
                    }
                    .item-droppable-block{
                        width: 17%;
    
                        @include respond(1600) {
                            width: 23%;
                        }
    
                        @include respond(1200) {
                            width: 28%;
                        }
                        .item-block{
                            width: 100%;
                            position:relative;
        
                            div{
        
                                .buttons-group{
                                    position:absolute;
                                    top: 2%;
                                    right: 2%;
                                    z-index: 3;
        
                                    button{
                                        padding: 0;
                                        min-width: unset;
                                        font-size: 20px;
                                        transition: all ease-out 150ms;
        
                                        &:hover{
                                            background: unset;
                                            transform: scale(1.2);
                                        }
                                    }
                                }
        
                                .img-wrapper{
                                    width: 100%;
                                    height: 200px;
                                    border-radius: 15px;
                                    box-shadow: 0px 0px 15px 1px #0000001c;
                                    position: relative;
                                    transition: all ease-out 300ms;
                                    cursor: pointer;
                                    z-index: 2;
                                    overflow: hidden;
        
                                    img{
                                        max-width: 100%;
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                        object-position: center;
                                        opacity: 1;
                                        transition: all ease-out 300ms;
                                    }
        
                                    .bigPlus{
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        transform: translate(-50%, -50%);
                                        opacity: 0;
                                        visibility: hidden;
                                        transition: all ease-out 300ms;
                                        z-index: 2;
        
                                        svg{
                                            width: 2.5em;
                                            height: 2.5em;
                                        }
                                    }
        
                                    &:hover{
                                        .bigPlus{
                                            opacity: 1;
                                            visibility: visible;                        
                                        }
        
                                        img{
                                            opacity: 0.5;
                                        }
                                    }
                                }
        
                                .slide-title{
                                    text-align:center;
                                    font-weight: 700;
                                    padding-top: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .slide-container{

            .slideImage{
                border: 2px solid $black-system;
                /* .region{
                    overflow:hidden;
                } */
            }
            
            .catName{
                display: flex;
                justify-content: center;
                column-gap: 16px;
                margin-bottom: 20px;
            }
            .slideImage{
                display: flex;
                justify-content: center;

                .region{
                    .MuiTextField-root{
                        background: #f3f7fd7e;

                        label{
                            color: #000;
                        }
                    }

                    .Check{
                        .small-modal{
                            display: none;

                            &.open{
                                display: block;
                                position:relative;
                                width: 300px;
                                height: auto;
                                background: #fff;
                                padding: 20px;
                                z-index: 999;
                                border-radius: 10px;
                                -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5); 
                                box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
                            }

                            .options{
                                margin-top: 20px;
                                display:flex;
                                flex-direction: column;
                            }
                        }
                    }
                }

                img{
                    max-width: 100%;
                    max-height: 800px;
                    height: 100%;
                }
            }
        }

        &.register{
            .hidden{
                display: none;
            }
        }
    }

    .MuiDialog-root{
        .MuiButton-containedSecondary{
            color: $black-system;
            background: $secondary-disabled;
        }
    }
}

.stud-app{
    .MuiBox-root{
        width: 100%;
        height: 100vh;

        @include respond(1200) {
            width: 100% !important;
            height: 100vh !important;
        }

        .close-modal-btn{
            top: 4%;
            left: 2%;
            z-index: 9;
            color: $primary-brand;
            background-color: $light-bg;
            border-radius: 50%;
            font-weight:900;
            box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.1);
            aspect-ratio: 1;

            svg{
                width: 40px;
                height: 40px;
            }
        }

        .slider-container{
            height: 100%;
            .swiper-container{
                height: 100%;

                .swiper-button-prev, .swiper-button-next{
                    color: $primary-brand;
                    text-shadow: 0px 0px 10px rgba(0,0,0,1);
                }

                .swiper-wrapper{
                    .swiper-slide{
                        img{
                            max-width: 100%;
                            width: 100%;
                            height: 100%;
                        }

                        .input-area{
                            input, textarea{
                                background: transparent;
                                border: 1px solid $dark-system;

                                &:hover, &:active, &:hover, &:focus{
                                    outline: none;
                                }

                                &:active, &:focus{
                                    background: rgba(255, 255, 255, 0.404);
                                }
                            }
                            input[type=submit]{
                                -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3); 
                                box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
                                transition: all ease-out 150ms;
                                border: none;
                                cursor:pointer;
                                -webkit-border-radius: 5px;
                                border-radius: 5px; 
                                padding: 5px 10px;
                                font-weight: 700;

                                &:hover{
                                    transform: scale(1.05);
                                }
                            }
                        }
                    }


                }
            }
        }
    }
}

.ad-app, .edit-slide-modal{
    .MuiBox-root{
        @include respond(1200) {
            width: 90% !important;
            height: 72vh !important;
        }
    }
}
