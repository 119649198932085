@import "theme.scss";

.PrivateNotchedOutline-root-1{
    border-radius: 9px !important;
}

.login-container{
    background: url('../images/bckg-login.png');
    background-repeat: no-repeat;
    background-position: contain;
    background-color: $light-bg;
    position: relative;
    height: 100vh;

    .login-box{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background: $light-bg;
        width: 452px;
        padding: 40px 56px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 6px 40px rgba(0, 0, 0, 0.1), 0px 8px 20px rgba(0, 0, 0, 0.05);
        -webkit-box-shadow: 0px 6px 40px rgba(0, 0, 0, 0.1), 0px 8px 20px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: 0px 6px 40px rgba(0, 0, 0, 0.1), 0px 8px 20px rgba(0, 0, 0, 0.05);
        border-radius: 30px;

        .logo{
            margin-bottom: 32px;

            img{
                max-width: 100%;
            }
        }

        h1.MuiTypography-body1{
            font-weight: bold;
            font-size: 28px;
            line-height: 33px;
            padding-bottom: 32px;
        }

        p.quote{
            font-weight: 500;
            font-size: 17px;
            line-height: 120%;
            color: $dark-system;
            margin: 0;
            padding-bottom: 8px;
            text-align: center;

            &:nth-of-type(2){
                padding-bottom: 48px;
            }
        }

        .form-control{
            .MuiFormControl-marginNormal{
                margin: 0 0 48px;
            }

            .fields{
                display: flex;
                flex-direction: column;
                row-gap: 48px;
                margin-bottom: 48px;
            }
        }

        .btn-wrapper{
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            column-gap: 42px;

            button{
                box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.05);
                border-radius: 9px;
                width: fit-content;
                font-weight: 500;
                font-size: 17px;
                text-transform: capitalize;
            }

            a{
                text-decoration: none;
                font-weight: 500;
                font-size: 17px;
                //opacity: 0;
            }
        }
    }

    .theguy_logo{
        position: absolute;
        right: 2%;
        bottom: 2%;
        transition: all ease-out 200ms;

        svg{
            path{
                transition: all ease-out 200ms;
            }
        }

        &:hover{
            svg{
                path{
                    fill: $secondary;
                }
            }
        }
    }
}

.logout-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    h1{
        margin-bottom: 30px;
    }

    .logout-form{
        display: flex;
        column-gap: 16px;
    }
}

.forgot-modal{
    .MuiBox-root{
        h3, .MuiInputBase-root{
            margin-bottom: 20px;
        }

    }
}