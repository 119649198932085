@import "theme";

section.header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;

    .header-wrapper{
        height: 100px;
        justify-content: center;
        box-shadow: 0px 12px 40px rgba(0, 0, 0, 0.1), 0px 12px 20px rgba(0, 0, 0, 0.05);
    }

    .MuiBox-root{
        .MuiPaper-root{
            .MuiToolbar-root{

                padding: 0 72px;

                .MuiGrid-container{
                    align-items: center;
                    column-gap: 30px;
        
                    .logo{
                        max-width: 117px;
                        max-height: 50px;
        
                        img{
                            max-width: 100%;
                        }
                    }
        
                    h5{
                        font-weight:700;
                        color: $black-system;
                        font-size: 20px;
                        margin-left: 20px;
                    }
        
                    .logout, .sign-up{
                        justify-content: flex-end;
                        display: flex;
                        margin-top: 4px;
        
                        a{
                            text-decoration: none;
                            color: $black-system;
                            font-weight: 700;
                            font-size: 18px;
                            border-bottom: 2px solid transparent;
                            transition: all ease-out 150ms;
                            &:hover{
                               border-bottom: 2px solid $black-system;
                            }
                        }
                    }
                
                    .current-user{
                        margin: 0 0 0 auto;
                        display: flex;
                        column-gap: 10px;
                        position:relative;
                        cursor: pointer;
        
                        &:hover{
                            .hover-menu{
                                opacity: 1;
                                visibility: visible;
                            }
                        }
        
                        .hover-menu{
                            opacity: 0;
                            visibility: hidden;
                            position: absolute;
                            top: 100%;
                            right: 0;
                            width: 60%;
                            min-width: 125px;
                            z-index: 9999;
                            background: #C5CCD9;
                            padding: 20px;
                            border-radius: 0 0 5px 5px;
                            -webkit-box-shadow: 0px 11px 15px 1px rgba(0,0,0,0.1); 
                            box-shadow: 0px 11px 15px 1px rgba(0,0,0,0.1);
                            display: flex;
                            flex-direction: column;
                            gap: 10px;
        
                            a{
                                display: flex;
                                align-items: center;
                                column-gap: 10px;
                                text-decoration: none;
                                color: $black-system;
                                transition: all ease-out 150ms;
        
                                &:hover{
                                    transform: scale(1.05);
                                    color: $secondary;
                                    font-weight: 700;
                                }
                            }
                        }
        
                        .MuiGrid-container{
                            column-gap: unset;
        
                            .MuiGrid-item h6{
                                color: $black-system !important;
                                font-weight: 700;
                                font-size: 18px;
                            }
                        }
        
                        .img-wrapper{
                            img{
                                border-radius: 50%;
                                box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
                                height: 50px;
                                width: 50px;
                                object-fit: cover;
                            }
                        }
                    }
                }
            }
        }
    }
}