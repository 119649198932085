@import "theme.scss";

.container-stud{
    margin-top: 172px;
    padding: 0 72px;

    h1, h2{
        font-weight: 700;
        font-size: 44px;
        line-height: 52px;
        margin-bottom: 32px;
    }

    .courses-container{
        //width: 100%;
        .scrollable{
            width: 100%;
            overflow-x: auto;
            padding-bottom: 20px;

            .blocks{
                display: flex;
                column-gap: 32px;
                width: fit-content;

                .course-block{
                    background: #fefefe;
                    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.05);
                    border-radius: 20px;
                    width: 400px;

                    .img-wrapper{
                        height: 200px;
                        display: flex;
                        justify-content: center;

                        img{
                            max-width: 100%;
                            width: 100%;
                            height: 100%;
                            object-position: center;
                            object-fit: cover;
                            border-radius: 20px 20px 0px 0px;
                        }
                    }

                    .text-wrapper{
                        padding: 32px 34px 16px;
                        display: flex;
                        flex-direction: column;
                        text-align: center;

                        h3{
                            font-weight: 700;
                            font-size: 28px;
                            line-height: 33px;
                            margin: 0;
                        }

                        h4{
                            font-weight: 500;
                            font-size: 17px;
                            line-height: 120%;
                            color: $dark-system;
                            margin: 10px 0 50px;
                        }

                        .progress{
                            margin-bottom: 24px;
                            p{
                                font-weight: 500;
                                font-size: 17px;
                                line-height: 120%;
                                color: $secondary;
                                margin-bottom: 12px;

                                &.complete{
                                    color: #47d855;
                                }
                            }

                            .MuiLinearProgress-root{
                                height: 12px;
                                border-radius: 16.8519px;

                                &.MuiLinearProgress-colorPrimary{
                                    background-color: #e0e6f6;
                                }

                                .MuiLinearProgress-barColorPrimary{
                                    background-color: $secondary;
                                    border-radius: 16.8519px;
                                }

                                &.complete{
                                    .MuiLinearProgress-barColorPrimary{
                                        background-color: #47d855;
                                    }
                                }
                            }
                        }

                        .primary-brand{
                            border: 2px solid $primary-brand;
                            border-radius: 9px;
                            padding: 5px;
                            font-weight: 500;
                            font-size: 17px;
                            line-height: 120%;
                            margin-bottom: 8px;
                            background-color: #fff;

                            span{
                                text-transform: capitalize;
                            }
                        }

                        .next{
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 14px;
                        }
                    }
                }
            }
        }
    }

    .eye-btn{
        position: fixed;
        bottom: 50px;
        right: 50px;
        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.05);
        border-radius: 9px;
        padding: 14px 30px;
        font-weight: 500;
        font-size: 17px;
        text-transform: capitalize;
        transition: all ease-out 200ms;
        column-gap: 8px;

        &:hover{
            transform: scale(1.02);
        }
    }
}

.modal-open-course{

    .MuiBox-root{
        border-radius: unset;
        background: #f3f7fd;

        .side-bar{
            position: fixed;
            top: 0;
            left: 0;
            width: 20%;
            height: 100%;
            padding: 130px 36px 60px;
            background: #eaeef6;
            overflow-y: auto;

            h4{
                font-weight: 700;
                font-size: 28px;
                line-height: 33px;
            }

            .course-chapters{
                position: relative;

                .MuiAccordion-root{
                    background: transparent;
                    box-shadow: none;
                    min-height: unset !important;
    
                    .MuiAccordionSummary-root{
                        padding: 0;

                        p{
                            font-weight: 900;
                            font-size: 17px; 
                        }
                        .MuiAccordionSummary-content{
                            margin: 0;
                        }
                    }
    
                    .chapter-name{
                        font-weight: 900;
                        font-size: 17px;
                        display: flex;
                        align-items: center;
                        position: relative;
    
                        &::before{
                            content: "";
                            width: 14px;
                            height: 14px;
                            min-width: 14px;
                            background: #eaeef6;
                            border: 2px solid #1E242E;
                            border-radius: 50%;
                            margin-right: 8px;
                        }

                        &::after{
                            // content:"⬤";
                            content: "";
                            width: 10px;
                            height: 10px;
                            background: transparent;
                            border-radius: 50%;
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            left: 4px;
                        }
    
                        &.passed{
                            &::before{
                                content:"";
                                background: url("../images/Icon_awesome-check-circle.png"), #eaeef6;
                                background-size: cover;
                                width: 18px;
                                height: 18px;
                                border: none;
                            }
                        } 

                        &.disabled{
                            pointer-events: none;
                        } 

                        &.active{
                            &::after{
                                background: #222;
                            } 
                            // &::before{
                            //     content:"⬤";
                            //     width: 14px;
                            //     height: 14px;
                            //     border: 2px solid #1E242E;
                            //     color: #1E242E;
                            //     border-radius: 50%;
                            //     line-height: 1;
                            //     font-size: 15px;
                            // }
                        }
                    }
                    
                    .MuiCollapse-root{
                        .chapter-content{
                            margin-left: 26px;
                            padding: 0;
                            min-height: unset;
                            position: relative;
    
                            &::before{
                                content: "";
                                position: absolute;
                                left: 0;
                                transform: translateX(-100%);
                                width: 18px;
                                height: 2px;
                                background: #1E242E; 
                            }

                            &::after{
                                // content:"⬤";
                                content: "";
                                width: 8px;
                                height: 8px;
                                background: transparent;
                                border-radius: 50%;
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                                left: 4px;
                            }
    
                            .MuiButton-label{
                                font-weight: 500;
                                font-size: 17px;
                                text-transform: capitalize;
                                text-align: start;
                                display: flex;
                                align-items: center;
    
                                &::before{
                                    content: "";
                                    width: 12px;
                                    height: 12px;
                                    min-width: 12px;
                                    background: transparent;
                                    border: 2px solid #1E242E;
                                    border-radius: 50%;
                                    margin-right: 8px;
                                }

                                &.passed{
                                    &::before{
                                        background: #47d855;
                                        border: 2px solid #47d855;
                                    }
                                }
                            }

                            &.active{

                                &::after{
                                    background: #222;
                                } 

                                .MuiButton-label{
                                    // &::before{
                                    //     content:"⬤";
                                    //     width: 12px;
                                    //     height: 12px;
                                    //     border: 2px solid #1E242E;
                                    //     color: #1E242E;
                                    //     border-radius: 50%;
                                    //     line-height: 1;
                                    //     font-size: 12px;
                                    // }

                                    
                                }
                            }
    
                        }

                    }
    
                    &:before {
                        content: unset;
                    }
                }
                .MuiAccordionSummary-root.Mui-expanded{
                    min-height: unset;
                }

                &:before {
                    content: "";
                    position: absolute;
                    top: 32px;
                    left: 8px;
                    width: 2px;
                    height: calc(100% - 48px);
                    background: #1E242E; 
                }

            }

            .go-back{
                position: fixed;
                right: 30px;
                bottom: 30px;

                .MuiButton-label{
                    font-weight: 500;
                    font-size: 17px;
                    line-height: 120%;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    column-gap: 5px;
                    text-transform: capitalize;
                }
            }
        }

        .container{
            width: 80%;
            margin: 116px 0 0 auto;

            .course-content{
                display: none;
                width: 80%;
                margin: 0 auto;

                .lesson-content{
                    margin-top: 20px;
                    h1, h2{
                        font-weight: 700;
                        font-size: 44px;
                        line-height: 52px;
                        text-transform: capitalize;
                        color: $black-system;
                        margin: 0;
                        margin-bottom: 30px;
                    }
    
                    p, ul, ol{
                        font-weight: 400;
                        font-size: 17px;
                        line-height: 120%;
                        color: $black-system;
    
                        img{
                            margin: 30px 0;
                            border-radius: 30px;
                            display: flex;
                            justify-content: center;
                            width: 100%;
                            object-fit: cover;
                            object-position: center;
                        }
                    }
    
                    iframe{
                        width: 100%;
                        height: 460px;
                        border-radius: 20px;
                        margin: 30px 0;
                    }
                }

                .lesson-files{
                    display: flex;
                    flex-direction: column;
                    row-gap: 20px;
                    margin-top: 20px;

                    a{
                        text-decoration: none;
                        width: fit-content;
                        padding: 10px 30px;
                        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.05);
                        border-radius: 9px;
                        display: flex;
                        align-items: center;
                        column-gap: 10px;
                        font-weight: 500;
                        font-size: 17px;
                        line-height: 120%;
                        transition: all ease-out 150ms;

                        &:hover{
                            transform: scale(1.02);
                        }
                    }
                }

                .slider-container{
                    width: 100%;
                    height: 65vh;

                    .swiper-container{
                        height: 100%;
        
                        .swiper-button-prev, .swiper-button-next{
                            color: $primary-brand;
                            text-shadow: 0px 0px 10px rgba(0,0,0,1);
                        }
        
                        .swiper-wrapper{
                            .swiper-slide{
                                overflow: hidden;

                                img{
                                    max-width: 100%;
                                    width: 100%;
                                    height: 100%;
                                }
        
                                .input-area{
                                    input, textarea{
                                        background: transparent;
                                        border: 1px solid $dark-system;
        
                                        &:hover, &:active, &:hover, &:focus{
                                            outline: none;
                                        }
        
                                        &:active, &:focus{
                                            background: rgba(255, 255, 255, 0.404);
                                        }
                                    }
                                    input[type=submit]{
                                        -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3); 
                                        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
                                        transition: all ease-out 150ms;
                                        border: none;
                                        cursor:pointer;
                                        -webkit-border-radius: 5px;
                                        border-radius: 5px; 
                                        padding: 5px 10px;
                                        font-weight: 700;
        
                                        &:hover{
                                            transform: scale(1.05);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .pagination-buttons{
                    margin-top: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                &.active{
                    display: block;
                }
            }

            .test-content{
                .title{
                    font-weight: bold;
                    font-size: 44px;
                    line-height: 52px;
                    margin-bottom: 60px;
                }

                button{
                    font-weight: 500;
                    font-size: 17px;
                    line-height: 120%;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    color: $secondary;
                    text-transform: capitalize;
                    border: 1px solid $secondary;
                    border-radius: 9px;
                    padding: 8px 40px;
                    margin: 20px 0 45px;
                    transition: all ease-out 150ms;

                    &:hover{
                        transform: scale(1.03);
                    }
                }

                .question-content{
                    p.question-counter{
                        font-weight: 500;
                        font-size: 17px;
                        line-height: 120%;
                        color: $light-system;
                        margin: 0;
                        padding-bottom: 8px;
                    }

                    h4{
                        font-weight: 700;
                        font-size: 28px;
                        line-height: 33px;
                        text-transform: capitalize;
                        margin: 0;
                        padding-bottom: 16px;
                    }

                    .answers{
                                        
                        .MuiFormGroup-root{
                            .answer{
                                display: flex;
                                align-items: center;
                                column-gap: 35px;

                                label{
                                    &.error{
    
                                        .MuiRadio-colorPrimary.Mui-checked{
                                            color: red;
                                        }      
                                        
                                    }
    
                                    &.success{
                                        .MuiRadio-colorPrimary.Mui-checked{
                                            color: rgb(38, 204, 79);
                                        } 
                                    }
                                }
                            }
                        }
                       
                    }
                    
                    .openEnded{
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        row-gap: 20px;

                        .MuiFormControl-root{
                            width: 90%;
    
                            .MuiOutlinedInput-root{
                                border-radius: 18px;
    
                                .MuiOutlinedInput-notchedOutline{
                                    border-color: $primary-disabled;
                                    border-width: 2px;
                                    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.05);
                                }
    
                                &.Mui-focused{
                                    .MuiOutlinedInput-notchedOutline{
                                        border-color: $secondary !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


