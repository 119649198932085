@import "theme.scss";

.container-admin{
    padding: 150px 72px;
    position: relative;

    @include respond(1200) {
        padding: 150px 50px;
    }

    .buttons-container{
        display: flex;
        gap: 16px;
        flex-wrap: wrap;

        .popup, .modal{
            @include respond(1800) {
                display: none ;
            }
        }

        .button{
            box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.05);
            border-radius: 9px;
            padding: 14px 30px;
            font-weight: 500;
            font-size: 17px;
            text-transform: capitalize;
            transition: all ease-out 200ms;

            .MuiButton-label{
                column-gap: 8px;
            }

            &:hover{
                transform: scale(1.02);
            }

            &:nth-of-type(2), &:nth-of-type(3), &:nth-of-type(4), &:nth-of-type(5){
                margin-left: -16px;

                @include respond(1800) {
                    margin-left: unset;
                }
            }
        }
    }

    .info-content{
        margin-top: 60px;
        display: flex;
        column-gap: 32px;

        .content-block{
            background: #E1E9F7;
            box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.05);
            -webkit-box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.05);
            border-radius: 24px;
            width: 21%;
            min-width: 300px;
            padding: 32px;
            position: relative;
            text-align: center;

            @include respond(1200) {
                min-width: 280px;
            }

            img.blue-icon-user{
                position: absolute;
                top: 20px;
                left: 28px;
            }

            .number{
                font-weight: 500;
                font-size: 72px;
                line-height: 84px;
            }

            p{
                color: $dark-system;
                font-weight: 500;
                font-size: 17px;
                line-height: 120%;
            }
        }
    }

    .eye-btn{
        position: fixed;
        bottom: 50px;
        right: 50px;
        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.05);
        border-radius: 9px;
        padding: 14px 30px;
        font-weight: 500;
        font-size: 17px;
        text-transform: capitalize;
        transition: all ease-out 200ms;
        column-gap: 8px;
        z-index: 999;

        &:hover{
            transform: scale(1.02);
        }
    }

    .courses-container{
        h1.title{
            font-size: 44px;
            line-height: 52px;
        }

        .courses-content{

            .category-block{

                .scrollable{
                    overflow-x: auto;
                    width: 100%;
                    padding-bottom: 20px;

                    .item-blocks{
                        display: flex;
                        column-gap: 32px;
    
                        .course-block{
                            width: 283px;
                            background: #FEFEFF;
                            box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.05);
                            border-radius: 18px;
                            display: flex;
                            flex-direction: column;
                            padding: 18px 38px;

                            ::-webkit-scrollbar{
                                width: 5px;
                            }
            
                            .title{
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                column-gap: 5%;
                                margin-bottom: 11px;
            
                                div{
                                    width: 15%;

                                    img{
                                        aspect-ratio: 1;
                                        max-width: 100%;
                                        border-radius: 50%;
                                        object-fit: cover;
                                        object-position: center;
                                    }
                                }
            
                                h4{
                                    font-weight: 500;
                                    font-size: 22px;
                                    line-height: 120%;
                                    margin: 0;
                                    width: 80%;
                                }
                            }
            
                            p.count{
                                text-align: center;
                                color: $dark-system;
                                font-weight: 500;
                                font-size: 17px;
                                line-height: 120%;
                                margin-bottom: 16px;
                            }
            
                            ul.chapters{
                                list-style-type: none;
                                padding: 0;
                                margin: 0 0 16px;
                                display: flex;
                                flex-direction: column;
                                // flex-wrap: wrap;
                                align-content: space-between;
                                row-gap: 8px;
                                height: 110px;
                                overflow-y: auto;
                                
                                li{
                                    font-weight: 500;
                                    font-size: 17px;
                                    line-height: 120%;
                                }
                            }
            
                            .info{
                                display: flex;
                                justify-content: space-between;
                                margin-bottom: 24px;
            
                                .info-item{
                                    display: flex;
                                    align-items: center;
                                    column-gap: 8px;
                                    font-weight: 500;
                                    font-size: 17px;
                                    line-height: 120%;
                                    color: $dark-system;
                                }
                            }
            
                            .btns{
                                display: flex;
                                justify-content: space-between;
                                button{
                                    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.05);
                                    border-radius: 9px;
                                    font-size: 16px;
                                    font-weight: 700;
                                    transition: all ease-out 200ms;
                
                                    &:hover{
                                        transform: scale(1.02);
                                    }
                                }
                            }
                        }
                    }
                }
                
            }

        }
        
    }

    .answers-container{
        h2.title{
            font-size: 44px;
            line-height: 52px;
            margin-bottom: 16px;
        }

        .options{
            display: flex;
            justify-content: space-between;

            .switch-toggle-btns{
                display: flex;
                column-gap: 16px;
                margin-bottom: 36px;
    
                button{
                    display: flex;
                    align-items: center;
                    text-align: center;
                    column-gap: 5px;
                    border-radius: 24px;
                    padding: 14px 30px;
                    font-family: "Roboto";  
                    font-weight: 500;
                    font-size: 17px;
                    color: $dark-system;
                    background: transparent;
                    border: 1px solid $dark-system;
                    cursor: pointer;
                    transition: all ease-out 300ms;
    
                    &:active, &:focus{
                        outline: none;
                    }
    
                    &.active{
                        color: $black-system;
                        background: #C5CCD9;
                        border: none;
                        pointer-events: none;
                    }
                }
            }

            .search-wrapper{
                // margin-right: 10px;
            
                .MuiFormControl-root{
                    margin-inline-start: 10px;
                }

            }
        }

        .search-wrapper.cats{
            display: flex;
            justify-content: flex-end;
            
            .category-wrapper{
                min-width: 300px;
                margin-bottom: 36px;
                margin-inline-start: auto;
            }
        }

        .answers-content{
            display: none;
            column-gap: 2%;
            row-gap: 32px;

            .answer-block{
                width: 49%;
                background: #FEFEFF;
                box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.05);
                border-radius: 24px;
                padding: 32px;

                .student-info{
                    display: flex;
                    align-items: center;
                    column-gap: 8px;

                    img{
                        width: 36px;
                        height: 36px;
                        border-radius: 50%;
                        object-fit: cover;
                        object-position: center;
                    }

                    h5{
                        font-weight: 500;
                        font-size: 17px;
                        line-height: 20px;
                        color: $dark-system;
                        margin: 0;
                    }
                }

                h4.question{
                    font-weight: 900;
                    font-size: 19px;
                    line-height: 23px;
                    margin: 16px 0 8px;
                }

                .course-info{
                    display: flex;
                    align-items: center;
                    column-gap: 8px;
                    margin-bottom: 8px; 

                    p{
                        color: $dark-system;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 20px;
                    }
                }

                .answer{
                    font-style: normal;
                    font-weight: 500;
                    font-size: 17px;
                    margin-bottom: 24px;
                }

                .check-btn{  
                    border: 1px solid $secondary;
                    border-radius: 9px;
                    color: $secondary;
                    font-weight: 500;
                    font-size: 17px;
                    line-height: 20px;
                    padding: 8px 30px;
                    
                }
            }

            &.active{
                display: flex;
                flex-wrap: wrap;
            }
        }

        .flags-content{
            display: none;
            column-gap: 32px;
            row-gap: 32px;

            @include respond(1600) {
                justify-content: space-between;
                column-gap: 4%;
            }

            .flag-block{
                width: 44%;
                background: #FEFEFF;
                box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.05);
                border-radius: 24px;
                padding: 32px;

                @include respond(1600) {
                    width: 48%;
                }

                .student-info{
                    display: flex;
                    align-items: center;
                    column-gap: 8px;

                    img{
                        width: 36px;
                        height: 36px;
                        border-radius: 50%;
                        object-fit: cover;
                        object-position: center;
                    }

                    h5{
                        font-weight: 500;
                        font-size: 17px;
                        line-height: 20px;
                        color: $dark-system;
                        margin: 0;
                    }
                }

                h4.question{
                    font-weight: 900;
                    font-size: 19px;
                    line-height: 23px;
                    margin: 16px 0 8px;
                }

                .course-info{
                    display: flex;
                    align-items: center;
                    column-gap: 8px;
                    margin-bottom: 8px; 

                    p{
                        color: $dark-system;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 20px;
                    }
                }

                .checked-answers{
                    display: flex;
                    flex-direction: column;
                    row-gap: 20px;
                    margin-top: 20px;
                    div{
                        display: flex;
                        justify-content: space-between;

                        p{
                            font-weight: 500;
                            font-size: 17px;
                            line-height: 20px;
                            padding-left: 32px;
                            position: relative;
                            width: 80%;
    
                            &::before{
                                content: "";
                                width: 12px;
                                height: 12px;
                                border: 2px solid $black-system;
                                border-radius: 50%;
                                background: #fff;
                                position:absolute;
                                left: 0;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                        }

                        &.incorrect, &.correct{
                            p{
                                color: $dark-system;
                            }
                        }

                        &.incorrect{
                            p{
                                &::before{
                                    border: 2px solid #E82121;
                                }
                            }
                            span{
                                font-weight: 500;
                                font-size: 17px;
                                color: #E82121;
                            }
                        }

                        &.correct{
                            p{
                                &::before{
                                    border: 2px solid #47D855;
                                }

                                &::after{
                                    content:'';
                                    background: #47D855;
                                    width: 8px;
                                    height: 8px;
                                    border-radius: 50%;
                                    position:absolute;
                                    left: 4px;
                                    top: 50%;
                                    transform: translateY(-50%);
                                }
                            }
                            span{
                                font-weight: 500;
                                font-size: 17px;
                                color: #47D855;
                            }
                        }
                    }
                }

                .resolve{
                    margin-top: 24px;
                    border: 1px solid $secondary;
                    border-radius: 9px;
                    padding: 8px 30px;
                    color: $secondary;
                    font-weight: 500;
                    font-size: 17px;
                    line-height: 20px;

                    .MuiButton-label{
                        display: flex;
                        align-items: center;
                        column-gap: 5px;
                    }
                }
            }

            &.active{
                display: flex;
                flex-wrap: wrap;
            }
        }
    }
}

.modal-add-course{

    .MuiBox-root{
        border-radius: unset;
        box-sizing: border-box;
        padding: 22px 72px;
        border: none;
        width: 100% !important;

        .container{
            width: 850px;
            margin: 128px auto 0;
           // overflow-y: scroll;

            .course-name{
                min-width: 500px;
                width: 100%;
            }

            .categories{
                margin: 16px 0;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                column-gap: 8px;
                row-gap: 8px;
                width: 100%;

                .cat-btn{
                    padding: 4px 18px;
                    border-radius: 25px;
                    color: $black-system;
                    background: $primary-disabled;
                    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.05);
                    border: none;
                    font-weight: 500;
                    font-size: 14px;
                    text-transform: capitalize;
                    transition: all ease-out 150ms;
                    cursor: pointer;

                    &.active{
                        background: $primary-brand;
                        font-weight: 600;
                    }

                    &:hover{
                        transform: scale(1.02);
                    }

                    &:active, &:focus{
                        outline: none;
                    }
                }
            }

            .img-container{
                .dropzone-ui{
                    min-height: 170px;
                    height: 260px;
                }
            }

            .chapters{
                .chapter{
                    margin: 20px 0;
                    .chapter-info{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .chapter-name{
                            margin-bottom: 10px;
                            p{
                                display: flex;
                                align-items: flex-end;
                                column-gap: 14px;
                                font-size: 24px;
                                line-height: 28px;
                                font-weight: 500;
                            }
                        }

                        button{
                            padding: 5px;
                            min-width: unset;
                        }
                    }

                    .additional-info{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .time{
                            display: none;
                            //display: flex;
                            align-items: center;
                            column-gap: 10px;

                            input{
                                width: 40px;
                            }
                        }
                    }

                    .chapter-tests-block, .chapter-lesson-block{
                        .item-blocks{
                            display: flex;
                            column-gap: 10px;
                            align-items: center;
                            margin: 30px 0;

                            p{
                                font-weight: 500;
                                padding-right: 30px;
                            }

                            button{
                                min-width: unset;
                                padding: 5px;
                            }
                        }
                    }

                    .chapter-buttons{
                        margin-top: 10px;
                        display: flex;
                        column-gap: 16px;
                    }

                }

                .add-btn{
                    margin-top: 20px;
                }
            }

            .assistants{

                margin-top: 30px;

                h4{
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 21px;
                }
    
                .existing-as{
                    max-height: 200px;
                    overflow-y: auto;
                    display: flex;
                    align-items: center;
                    column-gap: 16px;
    
                    .img-content{
                        img{
                            width: 60px;
                            height: 60px;
                            object-fit: cover;
                            object-position: center;
                            border-radius: 50%;
                        }
                        
                    }
    
                    .info-content{
                        p.name{
                            font-weight: 500;
                            font-size: 18px;
                            line-height: 21px;
                        }
    
                        p.seller{
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 16px;
                            color: $dark-system;
                        }
                    }
                }
    
                .btn{
                    margin-top: 16px;
                    display: flex;
                    align-items: center;
                    column-gap: 16px;
    
                    button{
                        font-size: 30px;
                        width: 60px;
                        height: 60px;
                        min-width: unset;
                    }
                }
            }
        }
    }
}

.buttons-bottom{
    display: flex;
    column-gap: 16px;
    justify-content: center;
    margin-top: 50px;
}

.modal-add-lesson{
    .MuiBox-root{
        border-radius: unset;
        box-sizing: border-box;
        padding: 22px 72px;
        border: none;

        .container{
            width: 90%;
            margin: 128px auto 0;
            display: flex;
            flex-direction:column;
            align-items: center;
            row-gap: 30px;

            .info{
                text-align: center;
            }

            .lesson-name{
                width: 40%;
            }

            .quill{
                width: 100%;
                max-height: 40vh;
                .ql-toolbar{
                    background: $primary-disabled;
                }
                .ql-container{
                    min-height: 300px;
                }
            }

            .files{
                width: 80%;

                .existing-files{
                    display: flex;
                    flex-direction: column;
                    row-gap: 16px;
                    margin: 30px 0;

                    .file{
                        display: flex;
                        align-items: center;
                        column-gap: 20px;

                    }
                }
            }

            .buttons-bottom{
                display: flex;
                column-gap: 16px;
                margin-top: 50px;
            }
        }
    }
}

.modal-add-test{
    .MuiBox-root{
        border-radius: unset;
        border: none;
        .container{
            width: 90%;
            margin: 100px auto 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 20px;
            
            h3{
                font-size: 44px;
                font-weight: 500;
                margin: 0;
            }
            h4{
                font-size: 30px;
                font-weight: 500;
                margin: 0;
            }

            .test-name-input{
                width: 30%;
            }

            .test-question{
                width: 60%;
                .MuiFormControl-root{
                    width: 100%;

                    .heading{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        p{
                            margin: 10px 0;
                            font-size: 24px;
                            font-weight: 500;
                        }
                    }

    
                    textarea{
                        border: 2px solid $primary-disabled;
                        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.05);
                        border-radius: 18px;
                        outline: none;
                        transition: all ease-out 150ms;
                        resize:none;
                        padding: 10px;
                        margin-bottom: 10px;
    
                        &:active, &:focus{
                            border: 2px solid $primary-brand;
                        }
                    }
                    
                    .answers{
                        display: flex;
                        flex-direction: column;
                        row-gap: 10px;
                        
                        .MuiFormControl-root{
                            .MuiInput-underline{
            
                                &::after {
                                    border-bottom: 2px solid rgb(194, 33, 33);
                                }
        
                            }
        
                            .MuiFormLabel-root{
                                color: rgb(194, 33, 33);
                            }
    
                            &:first-of-type{
                                .MuiInput-underline{
            
                                    &::after {
                                        border-bottom: 2px solid rgb(31, 177, 43);
                                    }
            
                                }
            
                                .MuiFormLabel-root{
                                    color: rgb(31, 177, 43);
                                }
                            }
                        }
                    }
                }

            }

            .open-ended{
                width: 573.7px; 
            }

            .add-question{

                .intro{
                    cursor: pointer;
                    display: flex;
                    column-gap: 10px;
                    align-items: center;
                    margin: 30px 0 20px;
                    justify-content: center;
                    
                    span{
                        background: $light-system;
                        font-size: 25px;
                        font-weight: 400;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 30px;
                        height: 30px;
                    }
                    p{
                        font-size: 22px;
                        font-weight: 500;
                    }

                }

                .btn-wrapper{
                    opacity: 0;
                    visibility: hidden;
                    display: flex;
                    column-gap: 16px;
                    transition: all ease-out 200ms;

                    &.open{
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }

            .buttons-bottom{
                display: flex;
                column-gap: 16px;
                justify-content: center;
                margin-top: 50px;
            }
        }
    }
}

.modal-add-ass{
    .MuiBox-root{
        overflow-y: hidden;

        .container{
            display: flex;
            flex-direction: column;
            row-gap: 30px;
            padding-top: 15px;
    
            .all-assistants{
                display: flex;
                flex-direction: column;
                row-gap: 20px;
                padding-right: 10px;
                max-height: 280px;
                overflow-y: auto;
            }
            .assistant-block{
                display: flex;
                align-items: center;
                justify-content: space-between;
    
                .ass-info{
                    display: flex;
                    align-items: center;
                    column-gap: 16px;
    
                    img{
                        width: 40px;
                        height: 40px;
                        object-fit: cover;
                        object-position: center;
                        border-radius: 50%;
                    }

                    p{
                        font-weight: 600;
                    }
                }

                .assign-btn{
                    background: $secondary-disabled;
                    color: $black-system;

                    &.active{
                        background: $secondary;
                        color: $light-bg;
                    }
                }
            }
        }
    }
}

.modal-add-reply{
    .MuiBox-root{
        position: relative;
        padding: 32px 74px;
        .container{
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            height: 100%;

            .student-info{
                display: flex;
                align-items: center;
                column-gap: 8px;
                margin-bottom: 40px;

                img{
                    width: 36px;
                    height: 36px;
                    border-radius: 50%;
                    object-fit: cover;
                    object-position: center;
                }

                h5{
                    font-weight: 500;
                    font-size: 17px;
                    line-height: 20px;
                    color: $dark-system;
                    margin: 0;
                }
            }

            .answer-block{
                border: 2px solid #C5CCD9;
                border-radius: 24px;
                padding: 32px;
                margin-bottom: 34px;
                width: 100%;

                .course-info{
                    display: flex;
                    align-items: center;
                    column-gap: 8px;
                    margin-bottom: 8px; 

                    p{
                        color: $dark-system;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 20px;
                    }
                }

                h4.question{
                    font-weight: 900;
                    font-size: 19px;
                    line-height: 23px;
                    margin: 16px 0 8px;
                }

                .answer{
                    font-style: normal;
                    font-weight: 500;
                    font-size: 17px;
                }

                .answers{
                    display: flex;
                    flex-direction: column;
                    row-gap: 20px;
                    margin-top: 20px;
                    div{
                        display: flex;
                        justify-content: space-between;

                        p{
                            font-weight: 500;
                            font-size: 17px;
                            line-height: 20px;
                            padding-left: 32px;
                            position: relative;
                            width: 80%;
    
                            &::before{
                                content: "";
                                width: 12px;
                                height: 12px;
                                border: 2px solid $black-system;
                                border-radius: 50%;
                                background: #fff;
                                position:absolute;
                                left: 0;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                        }

                        &.incorrect, &.correct{
                            p{
                                color: $dark-system;
                            }
                        }

                        &.incorrect{
                            p{
                                &::before{
                                    border: 2px solid #E82121;
                                }
                            }
                            span{
                                font-weight: 500;
                                font-size: 17px;
                                color: #E82121;
                            }
                        }

                        &.correct{
                            p{
                                &::before{
                                    border: 2px solid #47D855;
                                }

                                &::after{
                                    content:'';
                                    background: #47D855;
                                    width: 8px;
                                    height: 8px;
                                    border-radius: 50%;
                                    position:absolute;
                                    left: 4px;
                                    top: 50%;
                                    transform: translateY(-50%);
                                }
                            }
                            span{
                                font-weight: 500;
                                font-size: 17px;
                                color: #47D855;
                            }
                        }
                    }
                }
            }

            .reply-block{
                width: 100%;
                margin-bottom: 16px;

                p{
                    font-weight: bold;
                    font-size: 17px;
                    line-height: 20px;
                    padding-left: 24px;
                    margin-bottom: 10px;
                }

                .MuiTextField-root{
                    width: 100%;
                    .MuiOutlinedInput-root{
                        background: rgba(197, 204, 217, 0.2);
                        border-radius: 20px;
                        
                        .MuiOutlinedInput-notchedOutline{
                            border-radius: 20px;
                            border-width: 2px;
                        }
                    }

                }
            }

            .mail-to-assistant{
                margin: 0 auto 24px 0;
                background: $primary-brand;
                box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.05);
                border-radius: 49px;
                color: $black-system;
                transition: all ease-out 200ms;
                padding: 8px 14px;

                .MuiButton-label{
                    display: flex;
                    column-gap: 5px;
                }

                &:hover{
                    transform: scale(1.01);
                }

                &.disabled{
                    background: $primary-disabled;
                }
            }

            .action-btns{
                position: absolute;
                right: 0;
                bottom: 0;
                display: flex;
                column-gap: 32px;

                button{
                    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.05);
                    border-radius: 9px;
                    font-family: "Roboto";
                    font-weight: 500;
                    font-size: 17px;
                    line-height: 20px;
                    padding: 8px 30px;
                    display: flex;
                    align-items: center;
                    column-gap: 5px;
                    transition: all ease-out 200ms;
                    cursor: pointer;

                    &.disallow{
                        border: 1px solid #E82121;
                        color: #E82121;
                    }

                    &.allow{
                        background: #47D855;
                        border: 1px solid #47d355;
                    }

                    &:hover{
                        transform: scale(1.01);
                    }
                }
            }

        }

        .close-btn{
            position:absolute;
            top: 32px;
            left: 32px;
            min-width: unset;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            padding: 14px;
            display: flex;
            background: $light-system;
            color: $dark-system;
            transition: all ease-out 200ms;

            &:hover{
                transform: scale(1.05);
            }
        }
    }
}

.modal-managment{
    ::-webkit-scrollbar{
        width: 8px;
        height: 8px;
    }
    ::-webkit-scrollbar-thumb{
        background: $dark-system;
        border-radius: 30px;
        background-clip: padding-box;
    }
    ::-webkit-scrollbar-thumb:hover{
        background: $secondary-disabled;
        background-clip: padding-box;
    }
    ::-webkit-scrollbar-track{
        background: #fefeff; 
        margin: 0; 
        margin-bottom: 30px !important;
    }

    .MuiBox-root{
        border-radius: unset;
        border: none;
        padding: 0;
        font-family: "Roboto";
        background: $light-bg;
        overflow-y: hidden;

        .container{
            padding: 150px 72px 0;

            @include respond(1200) {
                padding: 150px 50px 0;

            }

            .top-panel{
                display: flex;
                justify-content: space-between;
                margin-bottom: 50px;

                .back-btn{
                    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.05);
                    border-radius: 9px;
                    font-weight: 500;
                    font-size: 17px;
                    line-height: 20px;
                    transition: all ease-out 200ms;

                    .MuiButton-label{
                        text-transform: none;
                    }

                    &:hover{
                        transform: scale(1.02);
                    }
                }

                .search-input{
                    width: 33%;

                    .MuiFormLabel-root{
                        color: $dark-system;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 120%;
                        &.Mui-focused{
                            color: $secondary;
                        }
                    }

                    .MuiOutlinedInput-root{
                        .MuiOutlinedInput-notchedOutline{
                            border-color: $dark-system;
                            border-radius: 9px;
                        }

                        &.Mui-focused{
                            .MuiOutlinedInput-notchedOutline{
                                border-color: $secondary;
                            }
                        }
                    }
                }
            }

            .table-container{
                width: 100%;

                .labels{
                    background: #1E242E;
                    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.05);
                    border-radius: 20px 20px 0px 0px;
                    padding: 10px 28px;
                    display: flex;
                    align-items: center;

                    .user-icon{
                        width: 4%;
                    }

                    .label{
                        border-width: 0 2px 0 0 ;
                        border-style: solid;
                        border-color: #5e636c;
                        font-weight: 900;
                        font-size: 17px;
                        line-height: 120%;
                        color: $light-bg;
                        display: flex;
                        align-items: center;
                        padding: 7.5px 16px;

                        &.user-name{
                            width: 16%;
                            border-left: 2px solid #5e636c;
                        }

                        &.course{
                            width: 16%;
                        }

                        &.chapter, &.lesson{
                            width: 12%;
                        }

                        &.current-flags, &.current-answers{
                            width: 16%;
                        }

                        &.seller{
                            width: 8%;
                            border-right: none;
                        }
                    }
                }

                .scrollable{
                    max-height: 630px;
                    overflow-y: auto;
                              
                    .table-rows{
                        width:100%;
                        display: flex;
                        flex-direction:column;
                        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.05);
                        margin-bottom: 30px;
                        .table-row{
                            background: #FEFEFF;
                            height: 90px;
                            display: flex;
                            align-items: center;
                            padding: 0 28px;
    
                            .cell{
                                padding: 0 10px;
                                font-weight: 500;
                                font-size: 17px;
                                line-height: 120%;
    
                                &.user-icon{
                                    width: 4%;
                                    padding: 0;
    
                                    img{
                                        width: 40px;
                                        height: 40px;
                                        border-radius: 9px;
                                        object-fit: cover;
                                        object-position: center;
                                    }
                                }
    
                                &.user-name{
                                    width: 16%;
                                }
        
                                &.course{
                                    width: 16%;
                                }

                                &.chapter, &.lesson{
                                    width: 12%;
                                    text-align: center;
                                }
        
                                &.current-flags, &.current-answers{
                                    width: 16%;
                                    display: flex;
                                    justify-content: space-around;
                                    
                                    .resolve{
                                        padding:0;
                                    
                                        .MuiButton-label{
                                            color:$dark-system;
                                            text-decoration: underline;
                                            font-size: 12px;
                                            line-height: 14px;
                                            text-transform: capitalize;
                                            letter-spacing: 1px;
                                            transition: all ease-out 150ms;
    
                                            .MuiSvgIcon-root{
                                                display: none;
                                            }
                                        }
    
                                        &:hover{
                                            background: unset;
                                            .MuiButton-label{
                                                color: $secondary;
                                            }   
                                        }
                                    }
                                }    
        
                                &.seller{
                                    width: 8%;
                                    display: flex;
                                    justify-content: space-between;
                                    flex-direction: row-reverse;
    
                                    button{
                                        padding: 0;
                                        min-width: unset;
                                        width: 40px;
                                        height: 40px;
                                        border-radius: 9px;
    
                                        &:hover{
                                            svg{
                                                fill: $secondary;
                                            }
                                        }

                                        &.delete{
                                            &:hover{
                                                svg{
                                                    fill: $system-error;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.modal-managment-popup{
    .close-btn{
        position: absolute;
        top: 32px;
        right: 32px;
        min-width: unset;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        padding: 14px;
        display: flex;
        background-color: #C5CCD9;
        color: #7B828F;
        transition: all ease-out 200ms;

        &:hover{
            transform: scale(1.1);
            background-color: #C5CCD9;
        }
    }
    .answers-container{
        h2.title{
            font-size: 44px;
            line-height: 52px;
            margin-bottom: 16px;
        }

        .switch-toggle-btns{
            display: flex;
            column-gap: 16px;
            margin-bottom: 36px;

            button{
                display: flex;
                align-items: center;
                text-align: center;
                column-gap: 5px;
                border-radius: 24px;
                padding: 14px 30px;
                font-family: "Roboto";  
                font-weight: 500;
                font-size: 17px;
                color: $dark-system;
                background: transparent;
                border: 1px solid $dark-system;
                cursor: pointer;
                transition: all ease-out 300ms;

                &:active, &:focus{
                    outline: none;
                }

                &.active{
                    color: $black-system;
                    background: #C5CCD9;
                    border: none;
                    pointer-events: none;
                }
            }
        }
        
        .answers-content{
            display: none;
            column-gap: 32px;
            row-gap: 32px;

            .answer-block{
                width: 44%;
                background: #FEFEFF;
                box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.05);
                border-radius: 24px;
                padding: 32px;

                .student-info{
                    display: flex;
                    align-items: center;
                    column-gap: 8px;

                    img{
                        width: 36px;
                        height: 36px;
                        border-radius: 50%;
                        object-fit: cover;
                        object-position: center;
                    }

                    h5{
                        font-weight: 500;
                        font-size: 17px;
                        line-height: 20px;
                        color: $dark-system;
                        margin: 0;
                    }
                }

                h4.question{
                    font-weight: 900;
                    font-size: 19px;
                    line-height: 23px;
                    margin: 16px 0 8px;
                }

                .course-info{
                    display: flex;
                    align-items: center;
                    column-gap: 8px;
                    margin-bottom: 8px; 

                    p{
                        color: $dark-system;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 20px;
                    }
                }

                .answer{
                    font-style: normal;
                    font-weight: 500;
                    font-size: 17px;
                    margin-bottom: 24px;
                }

                .check-btn{  
                    border: 1px solid $secondary;
                    border-radius: 9px;
                    color: $secondary;
                    font-weight: 500;
                    font-size: 17px;
                    line-height: 20px;
                    padding: 8px 30px;
                    
                }
            }

            &.active{
                display: flex;
                flex-wrap: wrap;
            }
        }

        .flags-content{
            display: none;
            column-gap: 32px;
            row-gap: 32px;

            .flag-block{
                width: 44%;
                background: #FEFEFF;
                box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.05);
                border-radius: 24px;
                padding: 32px;

                .student-info{
                    display: flex;
                    align-items: center;
                    column-gap: 8px;

                    img{
                        width: 36px;
                        height: 36px;
                        border-radius: 50%;
                        object-fit: cover;
                        object-position: center;
                    }

                    h5{
                        font-weight: 500;
                        font-size: 17px;
                        line-height: 20px;
                        color: $dark-system;
                        margin: 0;
                    }
                }

                h4.question{
                    font-weight: 900;
                    font-size: 19px;
                    line-height: 23px;
                    margin: 16px 0 8px;
                }

                .course-info{
                    display: flex;
                    align-items: center;
                    column-gap: 8px;
                    margin-bottom: 8px; 

                    p{
                        color: $dark-system;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 20px;
                    }
                }

                .checked-answers{
                    display: flex;
                    flex-direction: column;
                    row-gap: 20px;
                    margin-top: 20px;
                    div{
                        display: flex;
                        justify-content: space-between;

                        p{
                            font-weight: 500;
                            font-size: 17px;
                            line-height: 20px;
                            padding-left: 32px;
                            position: relative;
                            width: 80%;
    
                            &::before{
                                content: "";
                                width: 12px;
                                height: 12px;
                                border: 2px solid $black-system;
                                border-radius: 50%;
                                background: #fff;
                                position:absolute;
                                left: 0;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                        }

                        &.incorrect, &.correct{
                            p{
                                color: $dark-system;
                            }
                        }

                        &.incorrect{
                            p{
                                &::before{
                                    border: 2px solid #E82121;
                                }
                            }
                            span{
                                font-weight: 500;
                                font-size: 17px;
                                color: #E82121;
                            }
                        }

                        &.correct{
                            p{
                                &::before{
                                    border: 2px solid #47D855;
                                }

                                &::after{
                                    content:'';
                                    background: #47D855;
                                    width: 8px;
                                    height: 8px;
                                    border-radius: 50%;
                                    position:absolute;
                                    left: 4px;
                                    top: 50%;
                                    transform: translateY(-50%);
                                }
                            }
                            span{
                                font-weight: 500;
                                font-size: 17px;
                                color: #47D855;
                            }
                        }
                    }
                }

                .resolve{
                    margin-top: 24px;
                    border: 1px solid $secondary;
                    border-radius: 9px;
                    padding: 8px 30px;
                    color: $secondary;
                    font-weight: 500;
                    font-size: 17px;
                    line-height: 20px;

                    .MuiButton-label{
                        display: flex;
                        align-items: center;
                        column-gap: 5px;
                    }
                }
            }

            &.active{
                display: flex;
            }
        }
    }
}